*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::selection {
  color: #f6f6fa;
  background: #52489c;
}

html{
  font-size: 16px;
}
body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Foreday Serif Black", "Avenir", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
  }
  a {
    color: #424b54;
  }
  .diagram-a>a{
    font-family: "Avenir", sans-serif;
    font-size: 14px !important;
    text-decoration: underline;
  } 
  select{
    -moz-appearance: none!important;
    background: transparent!important;
    background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")!important;
   -webkit-appearance: none!important;
   background-repeat: no-repeat!important;
      font-size: 1.4rem!important;
      background-position-x: 95%!important;
      background-position-y: 50%!important;
  }
  @font-face {
    font-family: "Avenir";
    font-display: auto;
    src: url("../webfonts/Avenir-Light.woff2") format("woff2"),
    url("../webfonts/Avenir-Light.woff") format("woff");
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }
  
  .left-navbar-selected-item{
    display: flex;
    border-top-right-radius: 8px;
    margin-top:-1rem;
    border-bottom-right-radius: 8px;
    background-color: #52489c;
    margin-left: -6.5rem;
    position: absolute;
    height: 2.7rem;
    width: 5.9rem;
  }

  .left-navbar-selected-subitem{
    display: flex;
    border-top-right-radius: 8px;
    margin-top:-1rem;
    border-bottom-right-radius: 8px;
    background-color: #52489c;
    margin-left: -7.5rem;
    position: absolute;
    height: 3.2rem;
    width: 5.9rem;
  }
  .left-navbar-selected-subitem-sr{
    margin-top:-0.6rem;
    height: 3.2rem;
  }

  
/* APP__1 */
.gridContainer {
  display: grid;
  grid-template-columns: [sidebar-start] 16rem [sidebar-end full-start] 1fr [full-end];
  grid-gap: 0rem;
}

.nav{
  margin-top: 10px;
}

#content {
  overflow-y: auto;
  overflow-x: hidden;
}

.fa, .fas{
  margin-left: 8px;
}

.fa-copy:before{
  color: #424b54
}

.copyDiv{
  display: flex;
  align-items: baseline;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.test{
  background-color: #F6F6FA;
  /* background-color: red; */
  border-radius: 30px;
}

.nav__submenu{
  opacity: 0;
  visibility: hidden;
  line-height: 0;
  padding: 0;
  margin: 0;
  height: 0;
}

.nav__submenu-close{
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: all .2s ease-in !important
}
/* lang */
.langDrop{
  display: none;
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  color: #424b54;
  overflow-wrap: break-word;
  /* padding: 8px; */
  /* cursor: pointer; */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

div.wrapper-dropdown {
  display: flex;
  /* align-items: center; */
  margin-left:4rem ;
  margin-top: 0.8rem;
}
.container-dropdown {
  width: 9rem;
}
.btn-dropdown {
  display: flex;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.3rem !important;
  background-color: white;
  width: 100%;
  padding: 0.5rem 1.5rem;
  position: relative;
  text-align: left;
  color: #52489c;
  font-size: 15px;
}
.i-dropdown{
  margin-top: 0.1rem;
  font-weight: 700;
}
button:hover {
  cursor: pointer;
}

ul.options {
  box-shadow: 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.05) !important;
  display: none;
  list-style: none;
  padding: 8px 1rem;
  margin-top: -4px;
}

ul.show {
  display: block;
}

ul.options li {
  padding: 6px 10px;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
  /* background: pink; */
  color: #52489c;
  cursor: pointer;
}
ul li.circle{
  list-style-type: circle !important;
  margin-left: 2rem;
}

@media (max-width: 1300px) and (min-width: 961px) {
  .gridContainer {
    grid-template-columns: 1fr;
    width: 97vw;
    grid-gap: 10px;
  }
/* 
  #lang {
    opacity: 0 !important;
  } */
}

@media (max-width: 960px) and (min-width: 750px) {
  .gridContainer {
    grid-gap: 8px;
    width: 97vw;
    grid-template-columns: min-content;
  }
  

}

@media (max-width: 749px) and (min-width: 320px) {
  .gridContainer {
    grid-gap: 8px;
    width: 95vw;
    grid-template-columns: min-content;
  }
  .js-toc-content.notes{
    overflow-x: auto;
  }
  .content.notes{
    margin-left: 2px !important;
  }
}

/* HEADER__1 */
.header {
  justify-self: end;
  grid-column: full-start/full-end;
}

.searchField {
  width: 300px;
  height: 38px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(82, 81, 81, 0.25);
  padding-left: 8px;
  color: #4b4b4b;
  margin: 0.7rem;
}

.searchFieldm {
  display: none;
}

@media (max-width: 1300px) and (min-width: 320px) {
  .purple {
    color: red
  }

  .header {
    display: none;
  }
  .searchFieldm {
    display: inline-block;
    width: 150px;
    height: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(82, 81, 81, 0.25);
    padding-left: 8px;
    color: #4b4b4b;
    position: absolute;
    /* position: sticky; */
    left: 40%;
    top: 25%;
    z-index: 1;
  }
}

@media (max-width: 600px) and (min-width: 320px) {
  .logo {
    transform: scale(0.7);
    position: absolute;
    left: -3%;
    top: 5%;
  }
  .searchFieldm {
    width: 100px;
    height: 32px;
    z-index: 1;
    left: 35%;
  }
}

.node-name--H3 {
  font-size: 15px !important;
}

/* MAIN__1 */
.mainContainer {
  grid-column: full-start / full-end;
  font-family: "Avenir", sans-serif;
}

.mainContent {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr !important;
  grid-gap: 1rem;
  background: linear-gradient(90deg, #fff 50%, rgba(30, 34, 37, 0.938) 50%);
  /* border-radius: 4px; */
}
.js-toc-content.notes{
  overflow-x: hidden;
}

.content {
  grid-gap: 1rem;
  grid-column: 1/2;
  align-self: start;
  width: 100%;
  font-size: 15px;
  text-align: justify;
  padding: 0px 8px 0 0px;
}

.content.li{
  padding-left: 15px;
  padding-top:2px;
  padding-bottom:2px;
}
.content.notes{
  font-size: 16px;
  margin-left: 12px;
}

.contentt {
  grid-gap: 1rem;
  grid-column: 1/2;
  align-self: start;
  width: 100%;
  font-size: 1rem;
  padding: 0px;
}
.contentt.notes{
  padding-top:2rem;
}

.codeLang{
  margin-top: 80px !important;
}
.positionCodeLang{
  display: flex;
  position: fixed;
}
.lang {
  color: white !important;
  font-weight: 600 !important;
  align-self: center !important;
  background-color: #52489c !important;
  padding: 10px !important;
  width: min-content !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  cursor: pointer;
}
.imports{
  display: block;
  /* position: fixed; */
  margin-top: 45px !important;
  color:white;
  background-color: #1e2225;
  padding: 3px 30px !important;
  margin: 0;
  font-size: 13px !important;
}
.mob-import{
  display: none;
}
.imports .theCode{
  padding: 2px !important;
}
.theCode {
  grid-column: 2 / 3;
}

pre {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* text-align: justify; */
}


.upload-data-feed {
  font-family: "Avenir", sans-serif !important;
  font-size: 17px !important;
}

.upload-data-feed:hover {
  color: #52489c;
}

.integrate_pixyle_solutions{
  font-family: "Avenir", sans-serif !important;
  font-size: 17px !important;
}

.integrate_pixyle_solutions:hover {
  color: #52489c;
}
.yellow{
   color: #d4ce95;
 }
.txt{
  color: #ce9077;
}
.blue{
  color: #87cfee;
}
.letBlue{
  color: #5494c4;
}
.green{
  color: #4abb95;
}
a,
li,
ul {
  list-style-type: none;
  text-decoration: none;
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  color: #424b54;
  line-height: 110%;
  overflow-wrap: break-word;
  padding: 8px;
  /* cursor: pointer; */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a{
  padding: 0px;
}

a:focus {
  color: #52489c;
}

a:hover {
  color: #52489c;
}

.cont {
  color: blue
}
.cont2 {
  color: red
}

/* a:visited {
    color: #424b54;;
  } */
  
table {
  width: 100%;
  border-width: 100%;
  border-collapse: collapse;
  color: #424b54;
  margin-bottom: 20px;
  text-align: left !important;
}

td {
  padding: 8px;
  font-size: 14px;
}

#upload_data_feed {
  font-size: 30px;
}

tr:nth-child(even) {
  background-color: #f6f6fa;
}

tr:nth-child(odd) {
  background-color: rgba(238, 238, 238, 0.144);
}

th {
  font-size: 15px;
  padding: 12px 8px;
  text-align: left;
  color: white;
  background-color: #52489c;
}

th:first-child {
  border-radius: 4px 0px 0 0;
}
@media (min-width: 1600px) {
  th:first-child{
    width: 170px;
  }
}

th:last-child {
  border-radius: 0px 4px 0 0;
  margin-bottom: 10px;
}

.theCode {
  background-color: #1e2225;
  /* border-radius: 4px; */
  padding: 20px 20px;
  color: white;
  font-size: 14px;
  overflow: auto;
  height: min-content;
  height: -moz-min-content;
}

h1 {
  font-size: 24px;
  color: #424b54;
  padding-left: 0px;
}

h2 {
  font-size: 20px !important;
  color: #424b54;
  outline: none!important;
  padding-left: 0px;
}

h3 {
  font-size: 18px !important;
  color: #424b54;
  padding-left: 0px;
  font-weight: 900;
}

h4 {
  font-size: 16px !important;
  color: #424b54;
  padding-left: 0px;
  font-weight: 600;
}

.unbold{
  font-weight: 500;
}

.redChar {
  color: #db2763;
}

.orangeChar {
  color: #00bfb2;
}

.purpleChar {
  color: #52489c;
}

.whiteChar {
  color: white;
}

.grayChar {
  color: #8e9aaf;
}

.yellChar {
  color: #ffe74c;
}

.noteText {
  font-size: 12px;
}

p {
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  color: #424b54;
  padding: 0;
  line-height: 140%;
  overflow-wrap: break-word;
}

.mainLink {
  background-color: #f6f6fa;
  border-radius: 4px;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: #424b54;
  padding: 10px;
  line-height: 160%;
  width: 100%;
  margin: 10px 0px;
  overflow: auto;
}

pre {
  font-size: 13px;
}

#lang {
  overflow: hidden !important;
}

@media (max-width: 1300px) {
  .langDrop{
    display: flex;
  } 
  .mob-import{
    display: block;
  }
}
@media (max-width: 320px) {
  div.wrapper{
    margin-top: 0.5rem;
  } 
}
@media (max-width: 1300px) and (min-width: 320px) {
  .mainContent {
    grid-template-columns: 1fr;
    grid-column: 1 / 1;
    background: linear-gradient(90deg, #fff 50%, #fff 50%);
    justify-items: center;
    margin: 0;
  }
  .firstContent{
    margin-top: 100px;
  }
  .mainLink {
    width: 100%;
  }
  .mainContainer {
    width: 94vw;
  }
  .sidenav {
    width: 100%;
  }
  .content {
    grid-gap: 1rem;
    grid-column: 1/2;
    padding-right: 5px;
    width: 92vw;
  }
  .theCode {
    grid-column: 1 / 2;
    width: 100%;
    padding: 20px;
  }
  #upload_data_feed {
    margin-top: 80px;
  }
  .codeLang {
    display: none !important;
  }

}

@media (max-width: 960px) and (min-width: 320px) {
  .mainContent {
    grid-template-columns: 1fr;
    grid-column: 1 / 1;
    background: linear-gradient(90deg, #fff 50%, #fff 50%);
    justify-items: center;
    width: 100%;
    /* margin-top: 100px; */
  }

  .firstContent{
    margin-top: 100px;
  }

  #upload_data_feed {
    font-size: 22px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
  .mainContainer {
    width: 92vw;
  }
}

/* SIDENAV__1 */
/* .nav__submenu {
  display: none;
}

.nav-item:hover .nav__submenu { 
      display: block;
      color: #52489c;
    
} */

#upload_data_feed {
  font-size: 30px;
  margin-top: 82px;
}

.header{
  position: fixed;
    height: 60px;
    background: #fff;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.sidenav {
  grid-column: sidebar-start / sidebar-end;
  grid-row: 1/3;
  position: fixed;
  box-shadow: 2px 1px 4px rgba(82, 81, 81, 0.15);
  list-style-type: none;
  height: 100vh;
}

.sidenavList {
  font-family: "Avenir", sans-serif;
  font-size: 0.95rem;
  font-weight: 550;
  margin-left: 10%;
  margin-top: 10%;
}

#v2Documentation{
  font-size: 15px;
  padding-left: 35px;
  position: absolute;
  bottom: 20px;
  color: #a3a9b1;
}
#v2Documentation3{
  font-size: 15px;
  
  position: absolute;
  bottom: 20px;
  left: 78px;
  color: #a3a9b1;
}
#v2Documentation4{
  font-size: 15px;

  position: absolute;
  bottom: 20px;
  left: 120px;
  color: #a3a9b1;
}

.bold{
  color: #75797e !important;
  font-weight: 800 !important;
}


a:link,
.sidenavListItem {
  font-family: "Avenir", sans-serif;
  /* color: #1a1423; */
  margin-top: 10px;
  font-weight: lighter;
  font-size: 17px;
}

a:hover{
  color: #52489c;
}

.sidenavListSubItem {
  font-weight: 100;
  color: #424b54;
  margin-left: 1rem;
  padding: 0.4rem;
}

.logo {
  margin: 0.7rem;
  justify-self: center;
  width: 150px;
  height: 38px;
}

label {
  display: none;
}

#menutoggle {
  display: none;
}

.is-active-li,
.is-active-link {
  color: #52489c;
}

.is-active-link::before {
  background-color: #52489c !important;
}

.nav-icon {
  display: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media (max-width: 1300px) and (min-width: 320px) {
  .sidenav {
    position: relative;
    height: 40vh;

  }

  #upload_data_feed {
    font-size: 30px;
    margin-top: -5px;
  }


  #v2Documentation{
    font-size: 15px;
    padding-left: 25px;
    color:#75797e;
  }

  #versionDiv{
    position: absolute; right: 220px; top: 77px;
  }
  
  #v2Documentation3{
    font-size: 15px;
    left: 75px;
    color:#75797e;
    width: min-content;
  }

  #v2Documentation4{
    font-size: 15px;
  
    color:#75797e;
    width: min-content;
  }

  .logo {
    margin: 10px 0px 0px 10px;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  .js-toc {
    display: none;
  }
  label {
    display: block;
    color: #424b54;
    font-size: 1.5rem;
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 14px;
  }
  input[type="checkbox"]:checked ~ .js-toc {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    box-shadow: 2px 1px 4px rgba(82, 81, 81, 0.15);
  }
  a:link,
  .sidenavListItem {
    font-family: "Avenir", sans-serif;
    color: #1a1423;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 2;
  }

  .upload-data-feed {
    font-family: "Avenir", sans-serif !important;
    font-size: 18px !important;
  }
  
  .integrate_pixyle_solutions{
    font-family: "Avenir", sans-serif !important;
    font-size: 18px !important;
  }

  .sidenavList {
    margin-left: 0%;
    margin-top: 0%;
  }
  .sidenav {
    position: fixed;
    height: 63px;
    background: #fff;
    top: 0;
    width: 100%;
    transition: all 0.3s ease;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
  .hide {
    top: -63px;
  }


  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ffff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    min-height: 100vh;
    margin-top: -20px;
    overflow-y: scroll;
  }
  

  .nav-links {
    padding: 0rem;
    width: 100%;
    display: table;
    line-height: 50%;
  }
  
  /* .nav__submenu {
    display: block;
  } */
  
  /* .nav-item:hover .nav__submenu { 
        display: block;
        color: #52489c;
      
  } */


  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .left-navbar-selected-item{
    margin-top:-0.5rem;
  }

  .left-navbar-selected-subitem{
    margin-top:-0.5rem;
  }

}

@media (max-width: 460px) and (min-width: 320px) {
  .searchFieldm {
   display: none
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    transform: translate(-100%, 30%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  #v2Documentation{
    font-size: 12px;
    padding-left: 25px;
    padding-right: 0px;
   
    color: #a3a9b1;
  }

  #v2Documentation3{
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
    left: 75px;
    color:#75797e;
    width: min-content;
  }

  #v2Documentation4{
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
   
    color:#75797e;
    width: min-content;
  }

  #versionDiv{
    top: 75px;
  }

  .logo {
    transform: scale(0.6);
    position: absolute;
    left: -7%;
    top: 5%;
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
  }
  #logolink {
    display: block;
    width: 5px;
  }
  .js-toc {
    margin-top: 42px;
  }
  .sidenav {
    position: relative;
    position: fixed;
    height: 63px;
    background: #fff;
    top: 0;
    width: 100%;
    transition: all 0.1s ease;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
  .hide {
    top: -63px;
  }

  .nav-menu.active {
    min-height: 100vh;
    overflow-y: scroll;
  }
}

.activeLang{
  background-color: white !important;
  color: #52489c !important;
}




/* Release notes v4 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::selection {
  color: #f6f6fa;
  background: #52489c;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Foreday Serif Black", "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Avenir";
  font-display: auto;
  src: url("../webfonts/Avenir-Light.woff2") format("woff2"),
    url("../webfonts/Avenir-Light.woff") format("woff");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* APP__1 */
.gridContainer {
  display: grid;
  grid-template-columns: [sidebar-start] 17rem [sidebar-end full-start] 1fr [full-end];
  grid-gap: 0rem;
}

@media (max-width: 1300px) and (min-width: 961px) {
  .gridContainer {
    grid-template-columns: 1fr;
    width: 97vw;
    grid-gap: 10px;
  }
  #lang {
    opacity: 0 !important;
  }
}

@media (max-width: 960px) and (min-width: 750px) {
  .gridContainer {
    grid-gap: 8px;
    width: 97vw;
    grid-template-columns: min-content;
  }
  

}

@media (max-width: 749px) and (min-width: 320px) {
  .gridContainer {
    grid-gap: 8px;
    width: 95vw;
    grid-template-columns: min-content;
  }
  .js-toc-content.notes{
    overflow-x: auto;
  }
  .content.notes{
    margin-left: 2px !important;
  }
}

/* HEADER__1 */
.header {
  justify-self: end;
  grid-column: full-start/full-end;
}

.searchField {
  width: 300px;
  height: 38px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(82, 81, 81, 0.25);
  padding-left: 8px;
  color: #4b4b4b;
  margin: 1rem;
}

.searchFieldm {
  display: none;
}

@media (max-width: 1300px) and (min-width: 320px) {
  .header {
    display: none;
  }
  .searchFieldm {
    display: inline-block;
    width: 180px;
    height: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(82, 81, 81, 0.25);
    padding-left: 8px;
    color: #4b4b4b;
    position: absolute;
    left: 40%;
    top: 24%;
  }
}

@media (max-width: 600px) and (min-width: 320px) {
  .logo {
    transform: scale(0.6);
    position: absolute;
    left: -5%;
    top: 0%;
  }
  .searchFieldm {
    width: 100px;
    height: 32px;
  }
}

.node-name--H3 {
  font-size: 15px !important;
}

/* MAIN__1 */
.mainContainer {
  grid-column: full-start / full-end;
  font-family: "Avenir", sans-serif;
}

.mainContent {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr !important;
  grid-gap: 1rem;
  background: linear-gradient(90deg, #fff 50%, rgba(30, 34, 37, 0.938) 50%);
  border-radius: 4px;
}
.js-toc-content.notes{
  overflow-x: hidden;
}

.content {
  grid-gap: 1rem;
  grid-column: 1/2;
  align-self: start;
  width: 100%;
  font-size: 15px;
  text-align: justify;
  padding: 0px 8px 0 0px;
}

.content.li{
  padding-left: 15px;
  padding-top:2px;
  padding-bottom:2px;
}
.content.notes{
  font-size: 16px;
  margin-left: 12px;
}

.contentt {
  grid-gap: 1rem;
  grid-column: 1/2;
  align-self: start;
  width: 100%;
  font-size: 1rem;
  padding: 0px;
}
.contentt.notes{
  padding-top:2rem;
}

.lang {
  color: white !important;
  font-weight: 600 !important;
  align-self: center !important;
  background-color: #52489c !important;
  padding: 10px !important;
  width: min-content !important;
  border-radius: 4px !important;
  margin-top: 4px !important;
}

.theCode {
  grid-column: 2 / 3;
}

pre {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* text-align: justify; */
}

a,
li,
ul {
  list-style-type: none;
  text-decoration: none;
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  color: #424b54;
  line-height: 130%;
  overflow-wrap: break-word;
  padding: 8px;
}

a:focus {
  color: #52489c;
}

a:hover {
  color: #52489c;
}

/* a:visited {
    color: #424b54;
  } */
table {
  width: 100%;
  border-width: 100%;
  border-collapse: collapse;
  color: #424b54;
  margin-bottom: 20px;
  text-align: left !important;
}

td {
  padding: 8px;
  font-size: 14px;
}

#title {
  margin-top: 80px;
  font-size: 30px;
  margin-left: 0 !important;
}

tr:nth-child(even) {
  background-color: #f6f6fa;
}

tr:nth-child(odd) {
  background-color: rgba(238, 238, 238, 0.144);
}

th {
  font-size: 15px;
  padding: 12px 8px;
  text-align: left;
  color: white;
  background-color: #52489c;
}

th:first-child {
  border-radius: 4px 0px 0 0;
}
@media (min-width: 1600px) {
  th:first-child{
    width: 170px;
  }
}

th:last-child {
  border-radius: 0px 4px 0 0;
  margin-bottom: 10px;
}

.theCode {
  background-color: #1e2225;
  border-radius: 4px;
  padding: 20px 20px;
  color: white;
  font-size: 14px;
  overflow: auto;
  height: min-content;
  height: -moz-min-content;
}

h1 {
  font-size: 24px;
  color: #424b54;
  padding-left: 0px;
}

h2 {
  font-size: 20px !important;
  color: #424b54;
  outline: none!important;
  padding-left: 0px;
}

h3 {
  font-size: 18px !important;
  color: #424b54;
  padding-left: 0px;
}

h4 {
  font-size: 17px !important;
  font-weight: bold !important;
  color: #424b54;
  font-weight: 900;
  padding-left: 0px;
  font-weight: 100;
}

.redChar {
  color: #db2763;
}

.orangeChar {
  color: #00bfb2;
}

.purpleChar {
  color: #52489c;
}

.whiteChar {
  color: white;
}

.grayChar {
  color: #8e9aaf;
}

.yellChar {
  color: #ffe74c;
}

.noteText {
  font-size: 12px;
}

p {
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  color: #424b54;
  padding: 0;
  line-height: 140%;
  overflow-wrap: break-word;
}

.mainLink {
  background-color: #f6f6fa;
  border-radius: 4px;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: #424b54;
  padding: 10px;
  line-height: 160%;
  width: 100%;
  margin: 10px 0px;
  overflow: auto;
}

pre {
  font-size: 13px;
}

#lang {
  overflow: hidden !important;
}

@media (max-width: 1300px) and (min-width: 320px) {
  .mainContent {
    grid-template-columns: 1fr;
    grid-column: 1 / 1;
    background: linear-gradient(90deg, #fff 50%, #fff 50%);
    justify-items: center;
    margin: 0;
  }
  .mainLink {
    width: 100%;
  }
  .mainContainer {
    width: 94vw;
  }
  .sidenav {
    width: 100%;
  }
  .content {
    grid-gap: 1rem;
    grid-column: 1/2;
    padding-right: 5px;
    width: 92vw;
  }
  .theCode {
    grid-column: 1 / 2;
    width: 100%;
    padding: 20px;
  }
  #title {
    margin-top: 80px;
  }
  .lang {
    display: none !important;
  }
}

@media (max-width: 960px) and (min-width: 320px) {
  .mainContent {
    grid-template-columns: 1fr;
    grid-column: 1 / 1;
    background: linear-gradient(90deg, #fff 50%, #fff 50%);
    justify-items: center;
    width: 100%;
  }
  #title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
  .mainContainer {
    width: 92vw;
  }
}

/* SIDENAV__1 */
.sidenav {
  grid-column: sidebar-start / sidebar-end;
  grid-row: 1/3;
  position: fixed;
  box-shadow: 2px 1px 4px rgba(82, 81, 81, 0.15);
  list-style-type: none;
  height: 100vh;
}

.sidenavList {
  font-family: "Avenir", sans-serif;
  font-size: 0.95rem;
  font-weight: 550;
  margin-left: 10%;
  margin-top: 10%;
}

#v2Documentation{
  font-size: 15px;
  padding-left: 35px;
  position: absolute;
  bottom: 20px;
  color: #a3a9b1;
}
#v2Documentation3{
  font-size: 15px;
  /* padding-left: 25px; */
  position: absolute;
  bottom: 20px;
  left: 70px;
  color: #a3a9b1;
}
#v2Documentation4{
  font-size: 15px;
  /* padding-left: 25px; */
  position: absolute;
  bottom: 20px;
  left: 120px;
  color: #a3a9b1;
}
.bolded{
  color: #75797e !important;
  font-weight: 800 !important;
}
a:link,
.sidenavListItem {
  font-family: "Avenir", sans-serif;
  color: #1a1423;
  margin-top: 10px;
  font-weight: lighter;
  font-size: 17px;
}

a:hover {
  color: #52489c;
}

.sidenavListSubItem {
  font-weight: 100;
  color: #424b54;
  margin-left: 1rem;
  padding: 0.4rem;
}

.logo {
  margin: 2rem 2rem;
  justify-self: center;
}

label {
  display: none;
}

#menutoggle {
  display: none;
}

.is-active-li,
.is-active-link {
  color: #52489c;
}

.is-active-link::before {
  background-color: #52489c !important;
}

@media (max-width: 1300px) and (min-width: 320px) {
  .sidenav {
    position: relative;
    height: 40vh;
    /* display: flex;
    justify-content: space-between; */
  }
  /* #v2Documentation{
    font-size: 15px;
    padding-left: 25px;
    margin-top: -20px;
  } */

  #v2Documentation{
    font-size: 15px;
    padding-left: 25px;
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:30px; */
    color: #a3a9b1;
  }

  #versionDiv{
    position: absolute; right: 200px; top: 80px;
  }
  
  #v2Documentation3{
    font-size: 15px;
    /* padding-left: 170px; */
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:  10px; */
    /* left: 65vw; */
    color:#75797e;
    width: min-content;
  }

  #v2Documentation4{
    font-size: 15px;
    /* padding-left: 170px; */
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:  10px; */
    /* left: 65vw; */
    color:#75797e;
    width: min-content;
  }

  .logo {
    margin: 10px 0px 0px 10px;
  }
  input[type="checkbox"] {
    display: none;
  }
  .js-toc {
    display: none;
  }
  label {
    display: block;
    color: #424b54;
    font-size: 1.5rem;
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 14px;
  }
  input[type="checkbox"]:checked ~ .js-toc {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    box-shadow: 2px 1px 4px rgba(82, 81, 81, 0.15);
  }
  a:link,
  .sidenavListItem {
    font-family: "Avenir", sans-serif;
    color: #1a1423;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .sidenavList {
    margin-left: 0%;
    margin-top: 0%;
  }
  .sidenav {
    position: fixed;
    height: 63px;
    background: #fff;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
  .hide {
    top: -63px;
  }
}

@media (max-width: 460px) and (min-width: 320px) {
  .searchFieldm {
    /* display: inline-block; */
    width: 80px;
    height: 32px;
    /* border: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(82, 81, 81, 0.25);
    padding-left: 8px;
    color: #4b4b4b;
    position: absolute; */
    left: 35%;
    /* top: 24%; */
  }
  #v2Documentation{
    font-size: 15px;
    padding-left: 25px;
    padding-right: 0px;
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:30px; */
    color: #a3a9b1;
  }

  #v2Documentation3{
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0px;
    /* padding-left: 170px; */
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:  10px; */
    /* left: 65vw; */
    color:#75797e;
    width: min-content;
  }

  #v2Documentation4{
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0px;
    /* padding-left: 170px; */
    /* position: absolute; */
    /* bottom: 4px; */
    /* right:  10px; */
    /* left: 65vw; */
    color:#75797e;
    width: min-content;
  }

  #versionDiv{
    position: absolute; right: 195px; top: 80px;
  }

  .logo {
    transform: scale(0.6);
    position: absolute;
    left: -7%;
    top: 0%;
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
  }
  #logolink {
    display: block;
    width: 5px;
  }
  .js-toc {
    margin-top: 42px;
  }
  .sidenav {
    position: relative;
    position: fixed;
    height: 63px;
    background: #fff;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: all 0.1s ease;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
  .hide {
    top: -63px;
  }
}

/* html, body, div, span,  object, iframe, table, caption, tbody, tfoot, thead, tr, th, td, 
del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, 
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, main, nav, ins {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;        
    line-height: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    min-width: 0; 
} */
.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}
.ui.dimmer {
  display: none;
  position: absolute;
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0,0,0,.85);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-transition: background-color .5s linear;
  transition: background-color .5s linear;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 10
  ;
}